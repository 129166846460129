import { useEffect, useState } from "react";
import { theme_color_black, theme_color_white } from "../../const";
import { useSelector } from "react-redux";
import { Flex, Image, Text } from "@chakra-ui/react";
import calendar_pic from "./../images/calendar_icon.svg";
import whatsapp_pic from "./../images/whatsapp_icon.svg";
import phone_pic from "./../images/phone_icon.svg";
import mail_pic from "./../images/mail_icon.svg";
import { useNavigate } from "react-router-dom";

export const ContactUs = ({ contactUsRef }) => {
  const navigate = useNavigate();
  const startBooking = () => {
    window.location.href = "https://www.airbnb.com/h/rileklu";
    // navigate("/thankyou");
  };
  const deviceType = useSelector((state) => state.DeviceType.screenType);
  const languageState = useSelector((state) => state.Language.language);
  const [isMobile, setIsMobile] = useState(deviceType === "mobile");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (deviceType === "wide") {
      setIsMobile(false);
    }
    if (deviceType === "narrow") {
      setIsMobile(false);
    }
    if (deviceType === "mobile") {
      setIsMobile(true);
    }
  }, [deviceType]);

  useEffect(() => {
    if (languageState === "English") {
      import(`../en.json`)
        .then((res) => {
          setTranslations(res.contactUs);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
    if (languageState === "Malay") {
      import(`../bm.json`)
        .then((res) => {
          setTranslations(res.contactUs);
        })
        .catch((error) => {
          console.log("Failed to load translations", error);
        });
    }
  }, [languageState]);

  return (
    <Flex
      ref={contactUsRef}
      flexDir={"column"}
      justifyContent={"space-around"}
      w={"100%"}
      bgColor={theme_color_white}
      p={isMobile ? "5%" : "50px"}
      pt={isMobile ? "15%" : "50px"}
    >
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        align={"center"}
        w={"100%"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "24px" : "60px"}
          color={theme_color_black}
        >
          {translations.tagline1}
          <Text
            as="span"
            fontSize={isMobile ? "24px" : "60px"}
            color={isMobile ? theme_color_black : "#AF6534"}
          >
            {translations.subTagline1}
          </Text>
        </Text>
      </Flex>
      <Flex
        justifyContent={isMobile ? "center" : "start"}
        w={"100%"}
        align={"center"}
        pt={isMobile ? "0px" : "0px"}
      >
        <Text
          textAlign={isMobile ? "center" : "start"}
          className="font-link-Anton"
          fontSize={isMobile ? "32px" : "44px"}
          color={"#AF6534"}
        >
          {translations.tagline2}
        </Text>
      </Flex>
      <Flex
        flexDir={isMobile ? "column" : "row"}
        justifyContent={"space-around"}
        w={"100%"}
        alignSelf={"center"}
        align={"center"}
        p={isMobile ? "0px" : "50px"}
        pt={isMobile ? "10%" : "100px"}
        pb={isMobile ? "10%" : "100px"}
      >
        <Flex
          bgColor={theme_color_black}
          borderRadius={"15px"}
          flexDir={"row"}
          align={"center"}
          justifyContent={"space-around"}
          p={"10px"}
          w={isMobile ? "93%" : "40%"}
          cursor={"pointer"}
          onClick={() => {
            startBooking();
          }}
        >
          <Image
            src={calendar_pic}
            align={"center"}
            width={isMobile ? "35px" : null}
          />
          <Text
            textAlign={"center"}
            className="font-link-Anton"
            fontSize={isMobile ? "24px" : "44px"}
            color={theme_color_white}
          >
            {translations.button1}
          </Text>
        </Flex>
        <Flex justifyContent={isMobile ? "center" : "start"} align={"center"}>
          <Text
            align={"center"}
            className="font-link-Anton"
            fontSize={isMobile ? "25px" : "44px"}
            color={theme_color_black}
            py={"20%"}
          >
            {translations.message1}
          </Text>
        </Flex>
        <Flex
          bgColor={theme_color_black}
          borderRadius={"15px"}
          flexDir={"row"}
          align={"center"}
          justifyContent={"space-around"}
          p={"10px"}
          w={isMobile ? "93%" : "40%"}
          cursor={"pointer"}
        >
          <a
            href="whatsapp://send?phone=60183531696&text=Hello%2C%20I%20am%20interested%20in%20your%20services"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Image
              src={whatsapp_pic}
              align={"center"}
              width={isMobile ? "35px" : null}
            />
            <Text
              textAlign={"center"}
              className="font-link-Anton"
              fontSize={isMobile ? "23px" : "44px"}
              color={theme_color_white}
            >
              {translations.button2}
            </Text>
          </a>
        </Flex>
      </Flex>
      <Flex
        flexDir={isMobile ? "column" : "row"}
        justifyContent={"start"}
        paddingY={isMobile ? "15px" : "50px"}
      >
        <Flex
          flexDir={isMobile ? "row" : "column"}
          justifyContent={isMobile ? "center" : "start"}
          w={isMobile ? "100%" : "50%"}
          alignItems={"center"}
        >
          <Image
            src={phone_pic}
            align={"center"}
            width={isMobile ? "30px" : null}
            mr={isMobile ? "5%" : null}
          />
          <Text
            align={"center"}
            className="font-link-ArchivoNarrow"
            fontSize={isMobile ? "18px" : "36px"}
            color={theme_color_black}
          >
            +60136180059 | +60183531696
          </Text>
        </Flex>
        <Flex
          flexDir={isMobile ? "row" : "column"}
          justifyContent={isMobile ? "center" : "start"}
          w={isMobile ? "100%" : "50%"}
          alignItems={"center"}
          pt={isMobile ? "20px" : "0px"}
        >
          <Image
            src={mail_pic}
            align={"center"}
            width={isMobile ? "35px" : null}
            mr={isMobile ? "5%" : null}
          />
          <Text
            align={"center"}
            className="font-link-ArchivoNarrow"
            fontSize={isMobile ? "18px" : "36px"}
            color={theme_color_black}
          >
            secret.oasis.co@gmail.com
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
